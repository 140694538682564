exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cubes-[id]-[expire]-index-js": () => import("./../../../src/pages/cubes/[id]/[expire]/index.js" /* webpackChunkName: "component---src-pages-cubes-[id]-[expire]-index-js" */),
  "component---src-pages-cubes-liquidity-js": () => import("./../../../src/pages/cubes/liquidity.js" /* webpackChunkName: "component---src-pages-cubes-liquidity-js" */),
  "component---src-pages-cubes-swap-js": () => import("./../../../src/pages/cubes/swap.js" /* webpackChunkName: "component---src-pages-cubes-swap-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-help-center-js": () => import("./../../../src/pages/help-center.js" /* webpackChunkName: "component---src-pages-help-center-js" */),
  "component---src-pages-how-js": () => import("./../../../src/pages/how.js" /* webpackChunkName: "component---src-pages-how-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-trading-[cat]-[id]-[index]-invest-js": () => import("./../../../src/pages/trading/[cat]/[id]/[index]/invest.js" /* webpackChunkName: "component---src-pages-trading-[cat]-[id]-[index]-invest-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

