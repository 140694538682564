import { toast } from "react-toastify";

const config = {
  chainID: "0x38",
  chainName: "Mainnet",
  rpcUrls: [
    "https://bsc-dataseed1.binance.org/",
    "https://bsc-dataseed1.ninicoin.io",
    "https://bsc-dataseed1.defibit.io",
  ],
  blockExplorerUrls: ["https://bscscan.com/"],
  FundManagerContracts: [
    /* ALGO  */ "0x30EcfB66BD5E5F9B7BD78B221c64D8e04208d909",
    /* FIXED */ "0x27cD57d79a633A5C7D892823998377F4d626f147",
    /* RACE  */ "0x51a919ADEB0683cf08B059955978855f12Cb36Af",
  ],
  FundManagerContracts_OLD: [
    /* ALGO  */ "0x30EcfB66BD5E5F9B7BD78B221c64D8e04208d909",
    /* FIXED */ "0x27cD57d79a633A5C7D892823998377F4d626f147",
    /* RACE  */ "0x51a919ADEB0683cf08B059955978855f12Cb36Af",
  ],
  HyperTokenContract: "0x5CAeCD1e44b354568557BeE3d1DC4b7c57fB11A6",
  FactoryContract: "0x4Ef8715D26cca0ba9D211086ff5791Ba952087d0",
  RouterContract: "0x38d302f10557c12796d4cA7514Ca6423Da0015F9",
  WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  HyperTokenDecimalsToShow: 4,
  FundManagerABIs: [
    [
      {
        inputs: [
          { internalType: "contract IBEP20", name: "_asset", type: "address" },
          { internalType: "uint256", name: "_duration", type: "uint256" },
          { internalType: "uint256", name: "_sharePrice", type: "uint256" },
          {
            components: [
              {
                internalType: "uint256",
                name: "depositFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "performanceFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "guaranteedBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "referralBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "minHyperTokenRatioBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "maxHyperTokenRatioBP",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerAlgo.Fees",
            name: "_fees",
            type: "tuple",
          },
          { internalType: "uint256", name: "_start", type: "uint256" },
          { internalType: "uint256", name: "_expiration", type: "uint256" },
          { internalType: "address", name: "_managedAddress", type: "address" },
          { internalType: "address", name: "_priceFeed", type: "address" },
          { internalType: "bool", name: "_enabled", type: "bool" },
        ],
        name: "addCube",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "contract HyperToken",
            name: "_hyperToken",
            type: "address",
          },
          { internalType: "address", name: "_backendAddress", type: "address" },
          {
            internalType: "contract IHyperdexPair",
            name: "_hyperTokenPricePair",
            type: "address",
          },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "contract IBEP20",
            name: "asset",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "duration",
            type: "uint256",
          },
        ],
        name: "AddCube",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "oldBackendAddress",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newBackendAddress",
            type: "address",
          },
        ],
        name: "BackendAddressChange",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_count", type: "uint256" },
        ],
        name: "depositFromSuspended",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "count",
            type: "uint256",
          },
        ],
        name: "DepositFromSuspended",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_sharesAmount", type: "uint256" },
        ],
        name: "earlyUnlockShares",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "EarlyUnlockShares",
        type: "event",
      },
      {
        inputs: [
          { internalType: "address payable", name: "_to", type: "address" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "extractBNB",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ExtractBNB",
        type: "event",
      },
      {
        inputs: [
          { internalType: "contract IBEP20", name: "_asset", type: "address" },
          { internalType: "address", name: "_to", type: "address" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "extractToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "contract IBEP20",
            name: "asset",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ExtractToken",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "contract IHyperdexPair",
            name: "oldHyperTokenPricePair",
            type: "address",
          },
          {
            indexed: true,
            internalType: "contract IHyperdexPair",
            name: "_hyperTokenPricePair",
            type: "address",
          },
        ],
        name: "HyperTokenPricePairChange",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
          {
            internalType: "uint256",
            name: "_hyperTokenAmount",
            type: "uint256",
          },
          { internalType: "address", name: "_referral", type: "address" },
        ],
        name: "invest",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "hyperTokenAmount",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "address",
            name: "_referral",
            type: "address",
          },
        ],
        name: "Invest",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_backendAddress", type: "address" },
        ],
        name: "setBackendAddress",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint16", name: "_bonusID", type: "uint16" },
          { internalType: "uint256", name: "_stepUSD", type: "uint256" },
          { internalType: "uint256", name: "_bonusRewardBP", type: "uint256" },
        ],
        name: "setBonus",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint16",
            name: "bonusID",
            type: "uint16",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "stepUSD",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "bonusRewardBP",
            type: "uint256",
          },
        ],
        name: "SetBonus",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          {
            components: [
              {
                internalType: "uint256",
                name: "depositFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "performanceFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "guaranteedBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "referralBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "minHyperTokenRatioBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "maxHyperTokenRatioBP",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerAlgo.Fees",
            name: "_fees",
            type: "tuple",
          },
          { internalType: "uint256", name: "_expiration", type: "uint256" },
          { internalType: "uint256", name: "_duration", type: "uint256" },
          { internalType: "address", name: "_managedAddress", type: "address" },
          { internalType: "address", name: "_priceFeed", type: "address" },
          { internalType: "bool", name: "_enabled", type: "bool" },
        ],
        name: "setCube",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
        ],
        name: "SetCube",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "contract IHyperdexPair",
            name: "_hyperTokenPricePair",
            type: "address",
          },
        ],
        name: "setHyperTokenPricePair",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "bool", name: "_enabled", type: "bool" }],
        name: "setStatus",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "bool",
            name: "enabled",
            type: "bool",
          },
        ],
        name: "StatusChange",
        type: "event",
      },
      {
        inputs: [
          { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          {
            internalType: "uint256",
            name: "_totalAssetAmount",
            type: "uint256",
          },
          { internalType: "bool", name: "_availUpdate", type: "bool" },
        ],
        name: "updateCube",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "totalAssetAmount",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "availUpdate",
            type: "bool",
          },
        ],
        name: "UpdateCube",
        type: "event",
      },
      {
        inputs: [{ internalType: "uint256", name: "_cubeId", type: "uint256" }],
        name: "withdraw",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "Withdraw",
        type: "event",
      },
      {
        inputs: [],
        name: "backendAddress",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "bonuses",
        outputs: [
          { internalType: "uint256", name: "stepUSD", type: "uint256" },
          { internalType: "uint256", name: "bonusRewardBP", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "canWithdraw",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "cubeInfo",
        outputs: [
          { internalType: "contract IBEP20", name: "asset", type: "address" },
          { internalType: "string", name: "symbol", type: "string" },
          {
            components: [
              { internalType: "uint256", name: "start", type: "uint256" },
              { internalType: "uint256", name: "expiration", type: "uint256" },
              { internalType: "uint256", name: "duration", type: "uint256" },
              {
                internalType: "uint256",
                name: "lastUpdateBlock",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerAlgo.Times",
            name: "times",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "depositFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "performanceFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "guaranteedBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "referralBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "minHyperTokenRatioBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "maxHyperTokenRatioBP",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerAlgo.Fees",
            name: "fees",
            type: "tuple",
          },
          {
            components: [
              { internalType: "uint256", name: "shares", type: "uint256" },
              { internalType: "uint256", name: "assetAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "pendingAssetAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "earlyUnlockedShares",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "earlyUnlockedAssetAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "lastAvailUpdate",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenAmount",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerAlgo.Totals",
            name: "totals",
            type: "tuple",
          },
          { internalType: "uint256", name: "sharePrice", type: "uint256" },
          {
            internalType: "uint256",
            name: "suspendedUsersCount",
            type: "uint256",
          },
          { internalType: "address", name: "managedAddress", type: "address" },
          { internalType: "address", name: "priceFeed", type: "address" },
          { internalType: "bool", name: "enabled", type: "bool" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "cubeLength",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "enabled",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "fundsAvailable",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "getActualProfit",
        outputs: [
          {
            components: [
              {
                internalType: "uint256",
                name: "assetInvestment",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenInvestment",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenInvestmentUSD",
                type: "uint256",
              },
              {
                components: [
                  {
                    internalType: "uint256",
                    name: "assetProfit",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "assetLoss",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "hyperTokenProfitBase",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "hyperTokenProfitBonus",
                    type: "uint256",
                  },
                ],
                internalType: "struct FundManagerAlgo.ProfitDetail",
                name: "profitDetail",
                type: "tuple",
              },
              { internalType: "uint256", name: "userElapsed", type: "uint256" },
              {
                internalType: "uint256",
                name: "secondsToExpiration",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "assetPriceUSD",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenPrice",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerAlgo.ProfitInfo",
            name: "profitInfo",
            type: "tuple",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getAUM",
        outputs: [
          { internalType: "uint256", name: "totalUsdAUM", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getHyperTokenPrice",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getInfo",
        outputs: [
          { internalType: "address", name: "_backendAddress", type: "address" },
          { internalType: "address", name: "_hyperToken", type: "address" },
          {
            internalType: "uint256",
            name: "_hyperTokenPrice",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
          { internalType: "uint256", name: "_backLimit", type: "uint256" },
          { internalType: "uint256", name: "_backStep", type: "uint256" },
        ],
        name: "getLedgerEntries",
        outputs: [
          {
            internalType: "uint256",
            name: "totalLedgerEntriesCount",
            type: "uint256",
          },
          { internalType: "uint256", name: "thisListCount", type: "uint256" },
          {
            components: [
              { internalType: "uint240", name: "timestamp", type: "uint240" },
              { internalType: "uint16", name: "operationType", type: "uint16" },
              { internalType: "uint256", name: "assetAmount", type: "uint256" },
              { internalType: "uint256", name: "shares", type: "uint256" },
              {
                internalType: "uint256",
                name: "profitAmount",
                type: "uint256",
              },
              { internalType: "uint256", name: "lossAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBase",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBonus",
                type: "uint256",
              },
              { internalType: "uint256", name: "assetPrice", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenPrice",
                type: "uint256",
              },
              { internalType: "uint256", name: "sharePrice", type: "uint256" },
            ],
            internalType: "struct FundManagerAlgo.LedgerEntry[]",
            name: "ledgerEntriesList",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "_user", type: "address" }],
        name: "getTotalUserActualProfit",
        outputs: [
          { internalType: "uint256", name: "usdInvestment", type: "uint256" },
          { internalType: "uint256", name: "usdProfit", type: "uint256" },
          { internalType: "uint256", name: "usdLoss", type: "uint256" },
          {
            internalType: "uint256",
            name: "hyperTokenProfitBase",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "hyperTokenProfitBonus",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "getUsdValue",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "hyperToken",
        outputs: [
          { internalType: "contract HyperToken", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "hyperTokenPricePair",
        outputs: [
          { internalType: "contract IHyperdexPair", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "suspendedUserExists",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "", type: "uint256" },
          { internalType: "address", name: "", type: "address" },
        ],
        name: "userInfo",
        outputs: [
          { internalType: "uint256", name: "assetAmount", type: "uint256" },
          {
            internalType: "uint256",
            name: "pendingAssetAmount",
            type: "uint256",
          },
          { internalType: "uint256", name: "shares", type: "uint256" },
          {
            components: [
              { internalType: "uint256", name: "shares", type: "uint256" },
              { internalType: "uint256", name: "assetAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenAmountUSD",
                type: "uint256",
              },
              { internalType: "uint256", name: "assetProfit", type: "uint256" },
              { internalType: "uint256", name: "assetLoss", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBase",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBonus",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "nextWithdrawalAllowed",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerAlgo.Unlocked",
            name: "earlyUnlockedValues",
            type: "tuple",
          },
          { internalType: "uint256", name: "price", type: "uint256" },
          { internalType: "uint256", name: "avgEntrySecond", type: "uint256" },
          {
            internalType: "uint256",
            name: "hyperTokenAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "hyperTokenUSDvalue",
            type: "uint256",
          },
          { internalType: "uint256", name: "bonusRewardBP", type: "uint256" },
          { internalType: "uint256", name: "referralBonusBP", type: "uint256" },
          { internalType: "bool", name: "referralUsed", type: "bool" },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    [
      {
        inputs: [
          { internalType: "contract IBEP20", name: "_asset", type: "address" },
          { internalType: "uint256", name: "_duration", type: "uint256" },
          {
            components: [
              {
                internalType: "uint256",
                name: "depositFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "yearlyReturnBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "guaranteedBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "referralBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenYearlyReturnBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "minHyperTokenRatioBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "maxHyperTokenRatioBP",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerFixed.Fees",
            name: "_fees",
            type: "tuple",
          },
          { internalType: "uint256", name: "_start", type: "uint256" },
          { internalType: "uint256", name: "_expiration", type: "uint256" },
          { internalType: "address", name: "_managedAddress", type: "address" },
          { internalType: "address", name: "_priceFeed", type: "address" },
          { internalType: "bool", name: "_enabled", type: "bool" },
        ],
        name: "addCube",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "contract HyperToken",
            name: "_hyperToken",
            type: "address",
          },
          { internalType: "address", name: "_backendAddress", type: "address" },
          {
            internalType: "contract IHyperdexPair",
            name: "_hyperTokenPricePair",
            type: "address",
          },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "contract IBEP20",
            name: "asset",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "duration",
            type: "uint256",
          },
        ],
        name: "AddCube",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "oldBackendAddress",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newBackendAddress",
            type: "address",
          },
        ],
        name: "BackendAddressChange",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "earlyUnlockAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "EarlyUnlockAmount",
        type: "event",
      },
      {
        inputs: [
          { internalType: "address payable", name: "_to", type: "address" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "extractBNB",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ExtractBNB",
        type: "event",
      },
      {
        inputs: [
          { internalType: "contract IBEP20", name: "_asset", type: "address" },
          { internalType: "address", name: "_to", type: "address" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "extractToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "contract IBEP20",
            name: "asset",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ExtractToken",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "contract IHyperdexPair",
            name: "oldHyperTokenPricePair",
            type: "address",
          },
          {
            indexed: true,
            internalType: "contract IHyperdexPair",
            name: "hyperTokenPricePair",
            type: "address",
          },
        ],
        name: "HyperTokenPricePairChange",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
          {
            internalType: "uint256",
            name: "_hyperTokenAmount",
            type: "uint256",
          },
          { internalType: "address", name: "_referral", type: "address" },
        ],
        name: "invest",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "hyperTokenAmount",
            type: "uint256",
          },
        ],
        name: "Invest",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_backendAddress", type: "address" },
        ],
        name: "setBackendAddress",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint16", name: "_bonusID", type: "uint16" },
          { internalType: "uint256", name: "_stepUSD", type: "uint256" },
          { internalType: "uint256", name: "_bonusRewardBP", type: "uint256" },
        ],
        name: "setBonus",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint16",
            name: "bonusID",
            type: "uint16",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "stepUSD",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "bonusRewardBP",
            type: "uint256",
          },
        ],
        name: "SetBonus",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          {
            components: [
              {
                internalType: "uint256",
                name: "depositFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "yearlyReturnBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "guaranteedBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "referralBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenYearlyReturnBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "minHyperTokenRatioBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "maxHyperTokenRatioBP",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerFixed.Fees",
            name: "_fees",
            type: "tuple",
          },
          { internalType: "uint256", name: "_expiration", type: "uint256" },
          { internalType: "uint256", name: "_duration", type: "uint256" },
          { internalType: "address", name: "_managedAddress", type: "address" },
          { internalType: "address", name: "_priceFeed", type: "address" },
          { internalType: "bool", name: "_enabled", type: "bool" },
        ],
        name: "setCube",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
        ],
        name: "SetCube",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "contract IHyperdexPair",
            name: "_hyperTokenPricePair",
            type: "address",
          },
        ],
        name: "setHyperTokenPricePair",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "bool", name: "_enabled", type: "bool" }],
        name: "setStatus",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "bool",
            name: "enabled",
            type: "bool",
          },
        ],
        name: "StatusChange",
        type: "event",
      },
      {
        inputs: [
          { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "unlockAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "UnlockAmount",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "bool", name: "_availUpdate", type: "bool" },
          { internalType: "uint256", name: "_newExpiration", type: "uint256" },
          {
            internalType: "uint256",
            name: "_newYearlyReturnBP",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_newHyperTokenYearlyReturnBP",
            type: "uint256",
          },
        ],
        name: "updateCube",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
        ],
        name: "UpdateCube",
        type: "event",
      },
      {
        inputs: [{ internalType: "uint256", name: "_cubeId", type: "uint256" }],
        name: "withdraw",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "Withdraw",
        type: "event",
      },
      {
        inputs: [],
        name: "backendAddress",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "bonuses",
        outputs: [
          { internalType: "uint256", name: "stepUSD", type: "uint256" },
          { internalType: "uint256", name: "bonusRewardBP", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "canWithdraw",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "cubeInfo",
        outputs: [
          { internalType: "contract IBEP20", name: "asset", type: "address" },
          { internalType: "string", name: "symbol", type: "string" },
          {
            components: [
              { internalType: "uint256", name: "start", type: "uint256" },
              { internalType: "uint256", name: "expiration", type: "uint256" },
              { internalType: "uint256", name: "duration", type: "uint256" },
              {
                internalType: "uint256",
                name: "lastUpdateBlock",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerFixed.Times",
            name: "times",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "depositFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "yearlyReturnBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "guaranteedBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "referralBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenYearlyReturnBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "minHyperTokenRatioBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "maxHyperTokenRatioBP",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerFixed.Fees",
            name: "fees",
            type: "tuple",
          },
          {
            components: [
              { internalType: "uint256", name: "assetAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "unlockedAssetAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "earlyUnlockedAssetAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "lastAvailUpdate",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "unlockedHyperTokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "earlyUnlockedHyperTokenAmount",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerFixed.Totals",
            name: "totals",
            type: "tuple",
          },
          { internalType: "address", name: "managedAddress", type: "address" },
          { internalType: "address", name: "priceFeed", type: "address" },
          { internalType: "bool", name: "enabled", type: "bool" },
          {
            internalType: "uint256",
            name: "rewardChangesCount",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "cubeLength",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "enabled",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "fundsAvailable",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "getActualProfit",
        outputs: [
          {
            components: [
              {
                internalType: "uint256",
                name: "assetInvestment",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenInvestment",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenInvestmentUSD",
                type: "uint256",
              },
              {
                components: [
                  {
                    internalType: "uint256",
                    name: "assetProfit",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "unlockedAssetProfit",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "hyperTokenProfitBase",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "hyperTokenProfitBonus",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "unlockedHyperTokenProfitBase",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "unlockedHyperTokenProfitBonus",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "estimatedAssetProfit",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "estimatedHyperTokenProfit",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "estimatedUnlockedAssetProfit",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "estimatedUnlockedHyperTokenProfitBase",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "estimatedUnlockedHyperTokenProfitBonus",
                    type: "uint256",
                  },
                ],
                internalType: "struct FundManagerFixed.ProfitDetail",
                name: "profitDetail",
                type: "tuple",
              },
              { internalType: "uint256", name: "userElapsed", type: "uint256" },
              {
                internalType: "uint256",
                name: "secondsToExpiration",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "assetPriceUSD",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenPrice",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerFixed.ProfitInfo",
            name: "profitInfo",
            type: "tuple",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getAUM",
        outputs: [
          { internalType: "uint256", name: "totalUsdAUM", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getHyperTokenPrice",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getInfo",
        outputs: [
          { internalType: "address", name: "_backendAddress", type: "address" },
          { internalType: "address", name: "_hyperToken", type: "address" },
          {
            internalType: "uint256",
            name: "_hyperTokenPrice",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
          { internalType: "uint256", name: "_backLimit", type: "uint256" },
          { internalType: "uint256", name: "_backStep", type: "uint256" },
        ],
        name: "getLedgerEntries",
        outputs: [
          {
            internalType: "uint256",
            name: "totalLedgerEntriesCount",
            type: "uint256",
          },
          { internalType: "uint256", name: "thisListCount", type: "uint256" },
          {
            components: [
              { internalType: "uint240", name: "timestamp", type: "uint240" },
              { internalType: "uint16", name: "operationType", type: "uint16" },
              { internalType: "uint256", name: "assetAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "profitAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBase",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBonus",
                type: "uint256",
              },
              { internalType: "uint256", name: "assetPrice", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenPrice",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerFixed.LedgerEntry[]",
            name: "ledgerEntriesList",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_cubeId", type: "uint256" }],
        name: "getRewardChanges",
        outputs: [
          {
            internalType: "uint256",
            name: "rewardChangesCount",
            type: "uint256",
          },
          {
            components: [
              { internalType: "uint256", name: "timestamp", type: "uint256" },
              {
                internalType: "uint256",
                name: "previousAssetYearlyReturnBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "previousHyperTokenYearlyReturnBP",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerFixed.RewardChange[]",
            name: "rewardChangesList",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "_user", type: "address" }],
        name: "getTotalUserActualProfit",
        outputs: [
          { internalType: "uint256", name: "usdInvestment", type: "uint256" },
          { internalType: "uint256", name: "usdProfit", type: "uint256" },
          {
            internalType: "uint256",
            name: "hyperTokenProfitBase",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "hyperTokenProfitBonus",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "getUsdValue",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "hyperToken",
        outputs: [
          { internalType: "contract HyperToken", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "hyperTokenPricePair",
        outputs: [
          { internalType: "contract IHyperdexPair", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "", type: "uint256" },
          { internalType: "address", name: "", type: "address" },
        ],
        name: "userInfo",
        outputs: [
          { internalType: "uint256", name: "assetAmount", type: "uint256" },
          {
            components: [
              { internalType: "uint256", name: "assetAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenAmountUSD",
                type: "uint256",
              },
              { internalType: "uint256", name: "assetProfit", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBase",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBonus",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "nextWithdrawalAllowed",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "nextWithdrawalRequested",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerFixed.Unlocked",
            name: "unlockedValues",
            type: "tuple",
          },
          {
            components: [
              { internalType: "uint256", name: "assetAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenAmountUSD",
                type: "uint256",
              },
              { internalType: "uint256", name: "assetProfit", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBase",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBonus",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "nextWithdrawalAllowed",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "nextWithdrawalRequested",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerFixed.Unlocked",
            name: "earlyUnlockedValues",
            type: "tuple",
          },
          { internalType: "uint256", name: "avgEntrySecond", type: "uint256" },
          {
            internalType: "uint256",
            name: "hyperTokenAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "hyperTokenUSDvalue",
            type: "uint256",
          },
          { internalType: "uint256", name: "bonusRewardBP", type: "uint256" },
          { internalType: "uint256", name: "hyperBonusBP", type: "uint256" },
          { internalType: "uint256", name: "referralBonusBP", type: "uint256" },
          { internalType: "uint256", name: "avgHyperSecond", type: "uint256" },
          { internalType: "bool", name: "referralUsed", type: "bool" },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    [
      {
        inputs: [
          { internalType: "contract IBEP20", name: "_asset", type: "address" },
          {
            components: [
              {
                internalType: "uint256",
                name: "depositFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "guaranteedBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "referralBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "minHyperTokenRatioBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "maxHyperTokenRatioBP",
                type: "uint256",
              },
              { internalType: "uint256", name: "riskLevelBP", type: "uint256" },
            ],
            internalType: "struct FundManagerRace.Fees",
            name: "_fees",
            type: "tuple",
          },
          { internalType: "uint256", name: "_expiration", type: "uint256" },
          { internalType: "address", name: "_managedAddress", type: "address" },
          { internalType: "address", name: "_priceFeed", type: "address" },
          { internalType: "uint256", name: "_targetPrice", type: "uint256" },
          { internalType: "uint256", name: "_stopPrice", type: "uint256" },
          { internalType: "uint256", name: "_assetPrice", type: "uint256" },
          { internalType: "uint256", name: "_borderPrice", type: "uint256" },
          { internalType: "bool", name: "_enabled", type: "bool" },
        ],
        name: "addCube",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "contract HyperToken",
            name: "_hyperToken",
            type: "address",
          },
          { internalType: "address", name: "_backendAddress", type: "address" },
          {
            internalType: "contract IHyperdexPair",
            name: "_hyperTokenPricePair",
            type: "address",
          },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "contract IBEP20",
            name: "asset",
            type: "address",
          },
        ],
        name: "AddCube",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "oldBackendAddress",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newBackendAddress",
            type: "address",
          },
        ],
        name: "BackendAddressChange",
        type: "event",
      },
      {
        inputs: [{ internalType: "uint256", name: "_cubeId", type: "uint256" }],
        name: "earlyUnlockAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "EarlyUnlockAmount",
        type: "event",
      },
      {
        inputs: [
          { internalType: "address payable", name: "_to", type: "address" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "extractBNB",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ExtractBNB",
        type: "event",
      },
      {
        inputs: [
          { internalType: "contract IBEP20", name: "_asset", type: "address" },
          { internalType: "address", name: "_to", type: "address" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "extractToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "contract IBEP20",
            name: "asset",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ExtractToken",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "contract IHyperdexPair",
            name: "oldHyperTokenPricePair",
            type: "address",
          },
          {
            indexed: true,
            internalType: "contract IHyperdexPair",
            name: "_hyperTokenPricePair",
            type: "address",
          },
        ],
        name: "HyperTokenPricePairChange",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
          {
            internalType: "uint256",
            name: "_hyperTokenAmount",
            type: "uint256",
          },
          { internalType: "address", name: "_referral", type: "address" },
        ],
        name: "invest",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "hyperTokenAmount",
            type: "uint256",
          },
        ],
        name: "Invest",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_backendAddress", type: "address" },
        ],
        name: "setBackendAddress",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint16", name: "_bonusID", type: "uint16" },
          { internalType: "uint256", name: "_stepUSD", type: "uint256" },
          { internalType: "uint256", name: "_bonusRewardBP", type: "uint256" },
        ],
        name: "setBonus",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint16",
            name: "bonusID",
            type: "uint16",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "stepUSD",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "bonusRewardBP",
            type: "uint256",
          },
        ],
        name: "SetBonus",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          {
            components: [
              {
                internalType: "uint256",
                name: "depositFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "guaranteedBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "referralBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "minHyperTokenRatioBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "maxHyperTokenRatioBP",
                type: "uint256",
              },
              { internalType: "uint256", name: "riskLevelBP", type: "uint256" },
            ],
            internalType: "struct FundManagerRace.Fees",
            name: "_fees",
            type: "tuple",
          },
          { internalType: "uint256", name: "_expiration", type: "uint256" },
          { internalType: "address", name: "_managedAddress", type: "address" },
          { internalType: "address", name: "_priceFeed", type: "address" },
          { internalType: "bool", name: "_enabled", type: "bool" },
        ],
        name: "setCube",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
        ],
        name: "SetCube",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "contract IHyperdexPair",
            name: "_hyperTokenPricePair",
            type: "address",
          },
        ],
        name: "setHyperTokenPricePair",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_lastAssetPrice", type: "uint256" },
          { internalType: "uint256", name: "_refBlockNumber", type: "uint256" },
        ],
        name: "setPriceForSuspended",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
        ],
        name: "SetPriceForSuspended",
        type: "event",
      },
      {
        inputs: [{ internalType: "bool", name: "_enabled", type: "bool" }],
        name: "setStatus",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "bool",
            name: "enabled",
            type: "bool",
          },
        ],
        name: "StatusChange",
        type: "event",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_stopType", type: "uint256" },
        ],
        name: "stopCube",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "stopType",
            type: "uint256",
          },
        ],
        name: "StopCube",
        type: "event",
      },
      {
        inputs: [
          { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_lastAssetPrice", type: "uint256" },
          { internalType: "bool", name: "_availUpdate", type: "bool" },
        ],
        name: "updateCube",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "lastAssetPrice",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "availUpdate",
            type: "bool",
          },
        ],
        name: "UpdateCube",
        type: "event",
      },
      {
        inputs: [{ internalType: "uint256", name: "_cubeId", type: "uint256" }],
        name: "withdraw",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "cubeId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "Withdraw",
        type: "event",
      },
      {
        inputs: [],
        name: "backendAddress",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "bonuses",
        outputs: [
          { internalType: "uint256", name: "stepUSD", type: "uint256" },
          { internalType: "uint256", name: "bonusRewardBP", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "canWithdraw",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "cubeInfo",
        outputs: [
          { internalType: "contract IBEP20", name: "asset", type: "address" },
          { internalType: "string", name: "symbol", type: "string" },
          {
            components: [
              { internalType: "uint256", name: "expiration", type: "uint256" },
              {
                internalType: "uint256",
                name: "lastUpdateBlock",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerRace.Times",
            name: "times",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "depositFeeBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "guaranteedBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "referralBonusBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "minHyperTokenRatioBP",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "maxHyperTokenRatioBP",
                type: "uint256",
              },
              { internalType: "uint256", name: "riskLevelBP", type: "uint256" },
            ],
            internalType: "struct FundManagerRace.Fees",
            name: "fees",
            type: "tuple",
          },
          {
            components: [
              { internalType: "uint256", name: "assetAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "pendingAssetAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "earlyUnlockedAssetAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "lastAvailUpdate",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "avgEntryPrice",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "cumulativePosition",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "cumulativeRatio",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "lastOperationBlock",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerRace.Totals",
            name: "totals",
            type: "tuple",
          },
          {
            internalType: "uint256",
            name: "suspendedUsersCount",
            type: "uint256",
          },
          { internalType: "address", name: "managedAddress", type: "address" },
          { internalType: "address", name: "priceFeed", type: "address" },
          { internalType: "uint256", name: "targetPrice", type: "uint256" },
          { internalType: "uint256", name: "stopPrice", type: "uint256" },
          { internalType: "uint256", name: "lastAssetPrice", type: "uint256" },
          { internalType: "uint256", name: "borderPrice", type: "uint256" },
          { internalType: "bool", name: "enabled", type: "bool" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "cubeLength",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "enabled",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "fundsAvailable",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "getActualProfit",
        outputs: [
          {
            components: [
              {
                internalType: "uint256",
                name: "assetInvestment",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenInvestment",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenInvestmentUSD",
                type: "uint256",
              },
              {
                components: [
                  {
                    internalType: "uint256",
                    name: "assetProfit",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "assetLoss",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "hyperTokenProfitBase",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "hyperTokenProfitBonus",
                    type: "uint256",
                  },
                ],
                internalType: "struct FundManagerRace.ProfitDetail",
                name: "profitDetail",
                type: "tuple",
              },
              { internalType: "uint256", name: "userElapsed", type: "uint256" },
              {
                internalType: "uint256",
                name: "secondsToExpiration",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "assetPriceUSD",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenPrice",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerRace.ProfitInfo",
            name: "profitInfo",
            type: "tuple",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getAUM",
        outputs: [
          { internalType: "uint256", name: "totalUsdAUM", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getHyperTokenPrice",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getInfo",
        outputs: [
          { internalType: "address", name: "_backendAddress", type: "address" },
          { internalType: "address", name: "_hyperToken", type: "address" },
          {
            internalType: "uint256",
            name: "_hyperTokenPrice",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
          { internalType: "uint256", name: "_backLimit", type: "uint256" },
          { internalType: "uint256", name: "_backStep", type: "uint256" },
        ],
        name: "getLedgerEntries",
        outputs: [
          {
            internalType: "uint256",
            name: "totalLedgerEntriesCount",
            type: "uint256",
          },
          { internalType: "uint256", name: "thisListCount", type: "uint256" },
          {
            components: [
              { internalType: "uint240", name: "timestamp", type: "uint240" },
              { internalType: "uint16", name: "operationType", type: "uint16" },
              { internalType: "uint256", name: "assetAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "profitAmount",
                type: "uint256",
              },
              { internalType: "uint256", name: "lossAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBase",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBonus",
                type: "uint256",
              },
              { internalType: "uint256", name: "assetPrice", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenPrice",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerRace.LedgerEntry[]",
            name: "ledgerEntriesList",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "_user", type: "address" }],
        name: "getTotalUserActualProfit",
        outputs: [
          { internalType: "uint256", name: "usdInvestment", type: "uint256" },
          { internalType: "uint256", name: "usdProfit", type: "uint256" },
          { internalType: "uint256", name: "usdLoss", type: "uint256" },
          {
            internalType: "uint256",
            name: "hyperTokenProfitBase",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "hyperTokenProfitBonus",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "getUsdValue",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "hyperToken",
        outputs: [
          { internalType: "contract HyperToken", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "hyperTokenPricePair",
        outputs: [
          { internalType: "contract IHyperdexPair", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_cubeId", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
        ],
        name: "suspendedUserExists",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "", type: "uint256" },
          { internalType: "address", name: "", type: "address" },
        ],
        name: "userInfo",
        outputs: [
          { internalType: "uint256", name: "assetAmount", type: "uint256" },
          {
            internalType: "uint256",
            name: "pendingAssetAmount",
            type: "uint256",
          },
          {
            components: [
              { internalType: "uint256", name: "assetAmount", type: "uint256" },
              {
                internalType: "uint256",
                name: "cumulativePosition",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenAmountUSD",
                type: "uint256",
              },
              { internalType: "uint256", name: "assetProfit", type: "uint256" },
              { internalType: "uint256", name: "assetLoss", type: "uint256" },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBase",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "hyperTokenProfitBonus",
                type: "uint256",
              },
            ],
            internalType: "struct FundManagerRace.Unlocked",
            name: "earlyUnlockedValues",
            type: "tuple",
          },
          {
            internalType: "uint256",
            name: "nextWithdrawalAllowed",
            type: "uint256",
          },
          { internalType: "uint256", name: "avgEntryPrice", type: "uint256" },
          {
            internalType: "uint256",
            name: "cumulativePosition",
            type: "uint256",
          },
          { internalType: "uint256", name: "cumulativeRatio", type: "uint256" },
          {
            internalType: "uint256",
            name: "hyperTokenAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "hyperTokenUSDvalue",
            type: "uint256",
          },
          { internalType: "uint256", name: "bonusRewardBP", type: "uint256" },
          { internalType: "uint256", name: "referralBonusBP", type: "uint256" },
          {
            internalType: "uint256",
            name: "lastOperationBlock",
            type: "uint256",
          },
          { internalType: "uint256", name: "lastAssetPrice", type: "uint256" },
          { internalType: "bool", name: "referralUsed", type: "bool" },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
  ],
  HyperTokenABI: [
    {
      inputs: [{ internalType: "address", name: "_manager", type: "address" }],
      name: "addManager",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "approve",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
      name: "burn",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "subtractedValue", type: "uint256" },
      ],
      name: "decreaseAllowance",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "delegatee", type: "address" }],
      name: "delegate",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "delegatee", type: "address" },
        { internalType: "uint256", name: "nonce", type: "uint256" },
        { internalType: "uint256", name: "expiry", type: "uint256" },
        { internalType: "uint8", name: "v", type: "uint8" },
        { internalType: "bytes32", name: "r", type: "bytes32" },
        { internalType: "bytes32", name: "s", type: "bytes32" },
      ],
      name: "delegateBySig",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "delegator",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "fromDelegate",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "toDelegate",
          type: "address",
        },
      ],
      name: "DelegateChanged",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "delegate",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "previousBalance",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "newBalance",
          type: "uint256",
        },
      ],
      name: "DelegateVotesChanged",
      type: "event",
    },
    {
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "addedValue", type: "uint256" },
      ],
      name: "increaseAllowance",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_to", type: "address" },
        { internalType: "uint256", name: "_amount", type: "uint256" },
      ],
      name: "mint",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "mint",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      inputs: [{ internalType: "address", name: "_manager", type: "address" }],
      name: "removeManager",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transfer",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [
        { internalType: "address", name: "sender", type: "address" },
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "spender", type: "address" },
      ],
      name: "allowance",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "", type: "address" },
        { internalType: "uint32", name: "", type: "uint32" },
      ],
      name: "checkpoints",
      outputs: [
        { internalType: "uint32", name: "fromBlock", type: "uint32" },
        { internalType: "uint256", name: "votes", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "delegator", type: "address" }],
      name: "delegates",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "DELEGATION_TYPEHASH",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "DOMAIN_TYPEHASH",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "getCurrentVotes",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getManagers",
      outputs: [
        { internalType: "uint256", name: "count", type: "uint256" },
        { internalType: "address[]", name: "managersList", type: "address[]" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getOwner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "blockNumber", type: "uint256" },
      ],
      name: "getPriorVotes",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "managers",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "nonces",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "numCheckpoints",
      outputs: [{ internalType: "uint32", name: "", type: "uint32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ],
};

export const notify = {
  success: (text) => {
    toast.success(text, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
  },
  warning: (text) => {
    toast.warning(text, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
  },
  error: (text) => {
    toast.error(text, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
  },
};

export const currencyFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const UScurrencyFormatter = (value) => {
  let dollarUSLocale = Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return dollarUSLocale.format(value);
};

export const timeFormatter = (seconds) => {
  var result = "";
  while (seconds > 60) {
    if (seconds >= 86400) {
      let days = Math.floor(seconds / 86400);
      result += (result !== "" ? ":" : "") + days + "d";
      seconds -= days * 86400;
    }
    if (seconds >= 3600) {
      let hours = Math.floor(seconds / 3600);
      result += (result !== "" ? ":" : "") + ("0" + hours).slice(-2) + "h";
      seconds -= hours * 3600;
    }
    if (seconds >= 60) {
      let minutes = Math.floor(seconds / 60);
      result += (result !== "" ? ":" : "") + ("0" + minutes).slice(-2) + "m";
      seconds -= minutes * 60;
    }
  }
  result += (result !== "" ? ":" : "") + ("0" + seconds).slice(-2) + "s";
  return result;
};

export const languages = {
  en: {
    label: "English",
    lang: "ENG",
    flag: "flageng.png",
    bottom: "180px",
    left: "20px",
    dir: "ltr",
    active: true,
  },
  it: {
    label: "Italian",
    lang: "ITA",
    flag: "flagita.png",
    bottom: "60px",
    left: "20px",
    dir: "ltr",
    active: false,
  },
  fr: {
    label: "Français",
    lang: "FRA",
    flag: "flagfra.png",
    bottom: "100px",
    left: "20px",
    dir: "ltr",
    active: false,
  },
  de: {
    label: "Deutsch",
    lang: "DEU",
    flag: "flagdeu.png",
    bottom: "140px",
    left: "20px",
    dir: "ltr",
    active: false,
  },
  tr: {
    label: "Türkçe",
    lang: "TUR",
    flag: "flagtur.png",
    bottom: "10px",
    left: "calc(50% - 25px)",
    dir: "ltr",
    active: false,
  },
  ru: {
    label: "Русский",
    lang: "RUS",
    flag: "flagrus.png",
    bottom: "50px",
    left: "calc(50% - 25px)",
    dir: "ltr",
    active: false,
  },
  br: {
    label: "Português",
    lang: "BRA",
    flag: "flagbra.png",
    bottom: "90px",
    left: "calc(50% - 25px)",
    dir: "ltr",
    active: false,
  },
  es: {
    label: "Español",
    lang: "SPA",
    flag: "flagspa.png",
    bottom: "130px",
    left: "calc(50% - 25px)",
    dir: "ltr",
    active: false,
  },
  ko: {
    label: "한국어",
    lang: "KOR",
    flag: "flagnkk.png",
    bottom: "60px",
    left: "calc(100% - 70px)",
    dir: "ltr",
    active: false,
  },
  "zh-yue": {
    label: "粵語 / 粤语",
    lang: "HKG",
    flag: "flaghkk.png",
    bottom: "100px",
    left: "calc(100% - 70px)",
    dir: "ltr",
    active: false,
  },
  ja: {
    label: "日本語",
    lang: "JAP",
    flag: "flagjap.png",
    bottom: "140px",
    left: "calc(100% - 70px)",
    dir: "ltr",
    active: false,
  },
  zh: {
    label: "中文",
    lang: "CHN",
    flag: "flagchi.png",
    bottom: "180px",
    left: "calc(100% - 70px)",
    dir: "ltr",
    active: false,
  },
};

export const APP =
  /*'https://dapper-scone-636d49.netlify.app'*/ "https://app.hyperdex.finance";
export const MAIN =
  /*'https://flourishing-kelpie-b426c5.netlify.app'*/ "https://hyperdex.finance";
export const links = {
  APP: APP,
  MAIN: MAIN,
  FIXED_INCOME: APP + "/#/cubes/1/0",
  ALGO_TRADING: APP + "/#/cubes/0/0",
  RACE_TRADING: APP + "/#/cubes/2/0",
  FIXED_INCOME_EXPIRED: APP + "/#/cubes/1/1",
  ALGO_TRADING_EXPIRED: APP + "/#/cubes/0/1",
  RACE_TRADING_EXPIRED: APP + "/#/cubes/2/1",
  SWAP: APP + "/#/cubes/swap",
  LIQUIDITY: APP + "/#/cubes/liquidity",
  TERMS: "/terms",
  PRIVACY: "/terms",
  FAQ: "/faq",
  COOKIE_DISCLAIMER: "/terms-of-service#cookie",
  PRIVACY_POLICY: "/terms#privacy",
  HOME: "/",
  HELP: "/help-center",
  TEAM: "/#team",
  CONTACT: "/help-center#contact",
  HOW: "/how",
  ACCOUNT: APP + "/#/account",
  STATS: "/stats",
  CERTIK_AUDIT: "https://www.certik.com/projects/hyperdex",
  BLOG: "/blog",
};

export default config;
