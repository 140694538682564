//Libs
import React, { useState, useEffect } from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Link } from "gatsby";
import Web3 from "web3";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Components
import Header from "../components/header/header";
import Footer from "../components/footer/footer";

import config, { notify, links } from "../config/config";

//Styling
import "../assets/hypdex.css";
import "../assets/hypdex-responsive.css";
import logo from "../assets/images/logo.png";

//hooks
import useNetwork from "../assets/hooks/useNetwork";

const PageWrapperContainer = ({ children }) => {
  const { setBSCnetwork } = useNetwork();
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === null
      ? false
      : localStorage.getItem("darkMode")
  );

  const [capcthaKey, setCaptchaKey] = useState(
    "6LcFsXgfAAAAADOidInzgBVyP1zj8yOlVPtxuYcL"
  );

  useEffect(() => {
    setCaptchaKey("6LcFsXgfAAAAADOidInzgBVyP1zj8yOlVPtxuYcL");
  }, []);

  const switchDarkMode = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("darkMode", darkMode);
  };

  const addNetwork = async () => {
    let web3 = new Web3(window.ethereum);
    setBSCnetwork(web3.currentProvider.chainId === "0x61");
    var network = 0;
    network = await web3.eth.net.getId();
    let netID = "0x" + network.toString(16);
    var params;
    if (netID === config.chainID) {
      notify.warning(
        "BSC " +
          config.chainName +
          "Network has already been added to Metamask."
      );
      return;
    } else {
      params = [
        {
          chainId: config.chainID,
          chainName: "Binance Smart Chain " + config.chainName,
          nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: config.rpcUrls, // ['https://bsc-dataseed1.binance.org/'],
          blockExplorerUrls: config.blockExplorerUrls, // ['https://bscscan.com/']
        },
      ];
    }
    window.ethereum
      .request({ method: "wallet_addEthereumChain", params })
      .then(() => console.log("Success"))
      .catch((error) => console.log("Error", error.message));
  };

  const registerToken = async (
    tokenAddress,
    tokenSymbol,
    tokenDecimals,
    tokenImage
  ) => {
    if (!window.ethereum) return false;
    const tokenAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: tokenImage,
        },
      },
    });
    return tokenAdded;
  };

  return (
    <>
      <ToastContainer
        className="toast-hyperdex"
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="page-wrapper">
        <Header
          darkMode={darkMode}
          handleChangeMode={switchDarkMode}
          handleAddNetwork={addNetwork}
          registerToken={registerToken}
        />

        <GoogleReCaptchaProvider reCaptchaKey={capcthaKey}>
          {children}
          <Footer />
        </GoogleReCaptchaProvider>
      </div>
      <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>

        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler">
            <i className="fa fa-times"></i>
          </span>

          <div className="logo-box">
            <Link to={links.HOME} aria-label="logo image">
              <img src={logo} width="155" alt="" />
            </Link>
          </div>

          <div className="mobile-nav__container"></div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <a
                href="mailto:info@hyperdex.finance"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@hyperdex.finance
              </a>
            </li>
          </ul>

          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <a
                href="https://twitter.com/HyperdexFinance"
                className="fab fa-twitter"
              >
                {" "}
              </a>
              <a
                href="https://t.me/HyperDexFinance"
                className="fab fa-telegram"
              >
                {" "}
              </a>
              <a href="https://hyperdex.medium.com" className="fab fa-medium">
                {" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageWrapperContainer;
