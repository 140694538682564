import { useState, useEffect } from "react";
import Web3 from "web3";

const useWalletAddress = () => {
  const [walletAddress, setWalletAddress] = useState("");

  useEffect(() => {
    let localWalletAddress = localStorage.getItem("walletAddress");
    if (window.ethereum && localWalletAddress) {
      let web3 = new Web3(window.ethereum);
      var account = web3.currentProvider.selectedAddress;
      if (account) {
        localStorage.setItem("walletAddress", account);
        setWalletAddress(account);
      } else {
        localStorage.setItem("walletAddress", "");
        setWalletAddress("");
      }
    }
  }, []);

  return { walletAddress, setWalletAddress };
};

export default useWalletAddress;
