import React, { useState } from "react";
import { Link } from "gatsby";
import { withTranslation } from "react-i18next";
// import { useGoogleReCaptcha, GoogleReCaptcha } from "react-google-recaptcha-v3";

import "../../assets/hypdex.css";
import "../../assets/hypdex-responsive.css";

import ApiService from "../../services/api_services";
import { notify, links } from "../../config/config";

const Footer = (props) => {
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const { t } = props;

  const [isAgree, setIsAgree] = useState(false);
  const [formEmail, setFormEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  const clickAgree = () => {
    setIsAgree(!isAgree);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const newToken = await executeRecaptcha("subscribe");

    // subscribe(formEmail, newToken);
    subscribe(formEmail);
  };

  function onChange(value) {}

  const subscribe = (email, captchaValue) => {
    const url =
      "https://test.hyperdex.cloud/web/subscribe/" +
      email +
      "/" +
      (captchaValue || "null");
    const apiService = new ApiService();
    apiService
      .fetch(encodeURI(url), { method: "GET" })
      .then((response) => {
        setIsSubscribed(response.result.subscribed === "true");
        if (response.result.subscribed) {
          notify.success(response.result.message);
        } else {
          notify.warning(response.result.message);
        }
      })
      .catch((error) => {
        setIsSubscribed(false);
        notify.error("Error subscribing");
        console.log(error);
      });
  };

  const handleEmailValue = (e) => {
    setFormEmail(e.target.value);
  };

  return (
    <footer className="site-footer">
      <div className="site-footer__top"></div>
      <div className="site-footer__middle">
        <div className="site-footer-shape"></div>
        <div className="container">
          <div className="site-footer__middle-inner">
            <div className="row">
              {/* <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              > */}
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="footer-widget__column footer-widget__about">
                  <h3 className="footer-widget__title">{t("Say Hi")}</h3>
                  <div className="footer-widget__about-text-box"></div>
                  <ul className="footer-widget__about-contact list-unstyled">
                    <li>
                      <div className="icon">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div className="text">
                        <a
                          href="mailto:info@hyperdex.finance"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          info@hyperdex.finance
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div
                className="col-xl-2 col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="200ms"
              > */}
              <div className="col-xl-2 col-lg-3 col-md-6">
                <div className="footer-widget__column footer-widget__links clearfix">
                  <h3 className="footer-widget__title">Links</h3>
                  <ul className="footer-widget__links-list list-unstyled clearfix">
                    <li>
                      <Link to={links.FAQ}>FAQ</Link>
                    </li>
                    <li>
                      <a
                        href="https://t.me/HyperDexFinance"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Telegram
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/HyperdexFinance"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://hyperdex.medium.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Medium
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div
                className="col-xl-2 col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="300ms"
              > */}
              <div className="col-xl-2 col-lg-3 col-md-6">
                <div className="footer-widget__column footer-widget__explore clearfix">
                  <h3 className="footer-widget__title">Explore</h3>
                  <ul className="footer-widget__explore-list list-unstyled clearfix">
                    <li>
                      <a
                        href="http://hyperdex.finance/docs/HyperDex_Whitepaper_v1.pdf"
                        target="_new"
                      >
                        White Paper
                      </a>
                    </li>
                    <li>
                      <a
                        /*href="https://bit.ly/HyperdexPrivateSale"*/
                        href="https://presale.hyperdex.finance"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Private Sale
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div
                className="col-xl-5 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="400ms"
              > */}
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="footer-widget__column footer-widget__newsletter">
                  <h3 className="footer-widget__title">{t("Subscribe")}</h3>

                  {isSubscribed ? (
                    <h5>{t("Thank you for subscribing!")}</h5>
                  ) : (
                    <form
                      className="footer-widget__newsletter-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="footer-widget__newsletter-input-box">
                        {/* <GoogleReCaptcha onVerify={(t) => onChange(t)} /> */}
                        <input
                          type="email"
                          placeholder={t("Email address")}
                          name="email"
                          required="required"
                          autoComplete="off"
                          onChange={handleEmailValue}
                        />
                        <button
                          type="submit"
                          className="footer-widget__newsletter-btn"
                          disabled={!isAgree}
                        >
                          <i className="far fa-paper-plane"></i>
                        </button>
                      </div>
                    </form>
                  )}

                  <div className="footer-widget__newsletter-bottom">
                    <i
                      className={"fa" + (isAgree ? " fa-check" : " fa-circle")}
                      style={
                        isAgree
                          ? {
                              color: "white",
                              border: "1px solid white",
                              borderRadius: "50%",
                            }
                          : {
                              color: "rgba(var(--hypdex-white-rgb), 0.2)",
                              border:
                                "1px solid rgba(var(--hypdex-white-rgb), 0.2)",
                              borderRadius: "50%",
                            }
                      }
                      onClick={(e) => clickAgree(e)}
                    ></i>

                    <div className="footer-widget__newsletter-bottom-text">
                      <p>{t("I agree to all your terms and policies")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="row">
              <div className="col-xl-12">
                <div className="site-footer__bottom-inner">
                  <div className="site-footer__bottom-left">
                    <p className="site-footer__bottom-text">
                      © HYPERDEX 2021 - all rights reserved
                    </p>
                  </div>
                  <div className="site-footer__bottom-right">
                    <ul className="list-unstyled site-footer__bottom-menu">
                      <li>
                        <Link to={links.TERMS}>Terms &amp; Conditions</Link>
                      </li>
                      <li>
                        <Link to={links.PRIVACY}>Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withTranslation()(React.memo(Footer));
