import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Web3 from "web3";
import config, { notify, links } from "../../config/config";
import "../../assets/hypdex.css";
import "../../assets/hypdex-responsive.css";
import logo from "../../assets/images/logo.png";
import certikLogo from "../../assets/images/certik.svg";
import $ from "jquery";
import useWalletAddress from "../../assets/hooks/useWalletAddress";
import useNetwork from "../../assets/hooks/useNetwork";
import { useTranslation } from "react-i18next";

function Header(props) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const [netOK, setNetOK] = useState(false);
  const [walletAddressCompressed, setWalletAddressCompressed] = useState("");
  const { registerToken } = props;
  const { BSCnetwork, setBSCnetwork } = useNetwork();
  const { walletAddress, setWalletAddress } = useWalletAddress();

  const switchNetwork = async () => {
    setBSCnetwork(false);
    notify.warning("Please select " + config.chainName + " network!");
    props.handleAddNetwork();
  };

  const connectWallet = async (e) => {
    var account = "";
    if (window.ethereum && netOK) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      account = accounts[0];
      let web3 = new Web3(window.ethereum);
      if (web3.currentProvider.chainId === config.chainID) {
        setBSCnetwork(true);
        if (account) {
          localStorage.setItem("walletAddress", account);
          setWalletAddress(account);
        }
      } else {
        setBSCnetwork(false);
        notify.warning("Please select bsc testnet network!");
        props.handleAddNetwork();
      }
    } else {
      localStorage.setItem("walletAddress", "");
      setWalletAddress("");
    }

    if (account) {
      console.log(account);
      window.location.href = "#/account";
    }
  };

  const toggleMobileMenu = (el) => {
    el.stopPropagation();
    setMobileMenu(!mobileMenu);
  };

  const handleChangeLanguage = (item) => {
    i18n.changeLanguage(item);
    setLanguage(item);
  };

  const langChecked = (l) => {
    return language === l ? (
      <i
        className="fas fa-check"
        style={{ alignItems: "center", float: "right" }}
      ></i>
    ) : (
      ""
    );
  };

  document.addEventListener(
    "click",
    (e) => {
      e.stopPropagation();
      if (e.target.className !== "fa fa-angle-down") {
        setMobileMenu(false);
      }
    },
    false
  );

  useEffect(() => {
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      setNetOK(web3.currentProvider.chainId === config.chainID);
    }
  }, []);

  useEffect(() => {
    if (mobileMenu) {
      $(".mobilemenu-button").addClass("visible");
      $(".mobile-nav__wrapper").addClass("expanded");
      $("body").addClass("locked");
    } else {
      $(".mobilemenu-button").removeClass("visible");
      $(".mobile-nav__wrapper").removeClass("expanded");
      $("body").removeClass("locked");
    }
  }, [mobileMenu]);

  useEffect(() => {
    setWalletAddressCompressed(
      walletAddress.slice(0, 5) + "..." + walletAddress.slice(-6)
    );
  }, [walletAddress]);

  const menuContainer = () => {
    return (
      <>
        <div className="main-menu-wrapper__left">
          <div className="main-menu-wrapper__logo">
            <a href={links.HOME}>
              <img className="logo" src={logo} alt="" />
            </a>
          </div>
          <div className="main-menu-wrapper__main-menu">
            <div
              className="mobile-nav__toggler"
              onClick={(e) => toggleMobileMenu(e)}
            >
              <i className="fa fa-bars"></i>
            </div>
            <ul className="main-menu__list">
              <li className="mobilemenu-button">
                {walletAddress && netOK ? (
                  <a
                    className="thm-btn"
                    href={links.ACCOUNT}
                    style={{
                      lineHeight: "1.2em",
                      justifyContent: "center",
                      display: "block",
                    }}
                  >
                    {t("Account")}
                    <br />
                    <span style={{ color: "white" }}>
                      {walletAddressCompressed}
                    </span>
                  </a>
                ) : netOK ? (
                  <div
                    className="thm-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => connectWallet()}
                  >
                    {t("Connect Wallet")}
                  </div>
                ) : (
                  <div
                    className="thm-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => switchNetwork()}
                  >
                    {t("Switch Network")}
                  </div>
                )}
              </li>
              <li>
                <a href={links.BLOG}>{t("BLOG")}</a>
              </li>
              <li>
                <a href={links.HELP}>{t("Docs & Help")}</a>
              </li>
              <li>
                <a
                  /*href="https://bit.ly/HyperdexPrivateSale"*/
                  href="https://presale.hyperdex.finance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Private Sale")}
                </a>
              </li>
              <li>
                <a href={links.TEAM}>{t("Team")}</a>
              </li>
              <li className="dropdown">
                <Link to="#">
                  {t("Cubes")}
                  {/*&amp; Swap*/}
                </Link>
                <ul>
                  <li>
                    <a href={links.FIXED_INCOME}>{t("Fixed Income")}</a>
                  </li>
                  <li>
                    <a href={links.ALGO_TRADING}>{t("Algo Trading")}</a>
                  </li>
                  <li>
                    <a href={links.RACE_TRADING}>{t("Race Trading")}</a>
                  </li>
                  <li>
                    <a href={links.FIXED_INCOME_EXPIRED}>
                      {t("Fixed Income")} ({t("Expired")})
                    </a>
                  </li>
                  <li>
                    <a href={links.ALGO_TRADING_EXPIRED}>
                      {t("Algo Trading")} ({t("Expired")})
                    </a>
                  </li>
                  <li>
                    <a href={links.RACE_TRADING_EXPIRED}>
                      {t("Race Trading")} ({t("Expired")})
                    </a>
                  </li>
                  {/*
                  <li>
                    <Link to={links.SWAP}>Swap</Link>
                  </li>
                  <li>
                    <Link to={links.LIQUIDITY}>Liquidity</Link>
                  </li>
                  */}
                </ul>
              </li>
              <li className="dropdown">
                <Link to="#">{t("Links")}</Link>
                <ul>
                  <li>
                    <a href={links.ACCOUNT}>{t("Account")}</a>
                  </li>
                  <li>
                    <a href={links.STATS}>{t("Stats")}</a>
                  </li>
                  <li>{/* <Link to={HELP}>Help</Link> */}</li>

                  <li>
                    <a href={links.CONTACT}>{t("Contact")} </a>
                  </li>
                  <li>
                    <a href={links.HOW}>{t("How it works")}</a>
                  </li>
                  {window.ethereum && (
                    <li>
                      <Link
                        to=""
                        onClick={() =>
                          registerToken(config.HyperTokenContract, "HYP", 18)
                        }
                      >
                        Add HYP to Metamask
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
              {/*
              <li className="dropdown">
                <Link to="#">
                  <i className="fas fa-2x fa-language"></i>
                </Link>
                <ul>
                  <li>
                    <Link to="/" onClick={() => handleChangeLanguage("en")}>
                      EN {langChecked("en")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={() => handleChangeLanguage("zh")}>
                      ZH {langChecked("zh")}
                    </Link>
                  </li>
                </ul>
              </li>
              */}
            </ul>
          </div>
          <div
            className="main-menu-wrapper__logo"
            style={{ paddingLeft: "4em", width: "10em" }}
          >
            <a
              href={links.CERTIK_AUDIT}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5
                className="text-center"
                style={{ color: "white", lineHeight: ".8em" }}
              >
                {t("Audit by")}
              </h5>
              <img
                className="logo"
                src={certikLogo}
                alt=""
                style={{ width: "100%" }}
              />
            </a>
          </div>
        </div>
        <div className="main-menu-wrapper__right">
          <div className="main-menu-wrapper__call">
            <div className="main-menu-wrapper__call-number">
              {/* walletAddress && netOK ? (
                <>
                  <Link
                    className="thm-btn text-center"
                    to={links.ACCOUNT}
                    style={{ lineHeight: "1.2em" }}
                  >
                    {t("Account")}
                    <br />
                    <span style={{ color: "white" }}>
                      {walletAddressCompressed}
                    </span>
                  </Link>
                </>
              ) : netOK ? (
                <div
                  className="thm-btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => connectWallet()}
                >
                  {t("Connect Wallet")}
                </div>
              ) : (
                <div
                  className="thm-btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => switchNetwork()}
                >
                  {t("Switch Network")}
                </div>
              ) */}
              <a
                className="thm-btn text-center"
                href={links.APP}
                style={{ lineHeight: "1.2em" }}
              >
                {t("Go to the App")}
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <header className="main-header clearfix">
        <nav className="main-menu clearfix">
          <div className="main-menu-wrapper clearfix">{menuContainer()}</div>
        </nav>
      </header>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content">{menuContainer()}</div>
      </div>
    </>
  );
}

//export default withRouter(Header);
export default Header;
