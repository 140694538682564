import { useState, useEffect } from "react";
import Web3 from "web3";
import config, { notify, links } from "../../config/config";
const useNetwork = () => {
  const [BSCnetwork, setBSCnetwork] = useState(false);

  const addNetwork = async () => {
    let web3 = new Web3(window.ethereum);
    setBSCnetwork(web3.currentProvider.chainId === "0x61");
    var network = 0;
    network = await web3.eth.net.getId();
    let netID = "0x" + network.toString(16);
    var params;
    if (netID === config.chainID) {
      notify.warning(
        "BSC " +
          config.chainName +
          "Network has already been added to Metamask."
      );
      return;
    } else {
      params = [
        {
          chainId: config.chainID,
          chainName: "Binance Smart Chain " + config.chainName,
          nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: config.rpcUrls, // ['https://bsc-dataseed1.binance.org/'],
          blockExplorerUrls: config.blockExplorerUrls, // ['https://bscscan.com/']
        },
      ];
    }
    window.ethereum
      .request({ method: "wallet_addEthereumChain", params })
      .then(() => console.log("Success"))
      .catch((error) => console.log("Error", error.message));
  };
  return {
    BSCnetwork,
    setBSCnetwork,
    addNetwork,
  };
};

export default useNetwork;
